import React from "react";
import PropTypes from "prop-types";

import { TextField } from "@mui/material";

const NumberOfEventsSelector = ({ number_of_events, onChange }) => {

  return (
    <TextField
      size="small"
      name="number_of_events"
      label={"Max number of events in calendar"} 
      value={number_of_events}
      onChange={onChange}
      type="number"
      inputProps={{ inputMode: 'numeric'}}
      fullWidth
      color="warning"
    />    
  );
};

NumberOfEventsSelector.propTypes = {
  number_of_events:  PropTypes.string,
  onChange: PropTypes.func,
};

export default NumberOfEventsSelector;
