import React from 'react';
import FormSection from 'ui/FormSection';
import Description from 'ui/text/Description';
// import React, { useState } from 'react';
// import { useController, useSuspense } from '@rest-hooks/react';
// import { useParams } from 'react-router-dom';

// import PayoutResource from 'resources/organization/PayoutResource';

// import PayoutGenerator from './PayoutGenerator';
// import RequestConfirmedModal from './RequestConfirmedModal';

// import DataSelectionTable from 'components/DataTable/DataSelectionTable';
// import WithPermission from 'components/WithPermission';
// import SalesFallback from 'components/fallbacks/Sales';

// import { payoutColumns } from 'utils/tables/columns/payoutColumns';
// import { useError } from 'utils/useErrorController';

const StripePayoutsTabContent = () => {

  // const [open, setOpen] = useState(false);

  // const {fetch} = useController();
  // const {handleError} = useError();

  // const params = useParams();
  // const organizationId = parseInt(params.organizationId);

  // const payouts = useSuspense(PayoutResource.list(), {organization_id: organizationId});

  // const handleSelect = async (event) => {
  //   try {
  //     await fetch(PayoutResource.create(), { organization_id: organizationId}, {start_date: event.target.value })
  //     setOpen(true);
  //   } catch (error) {
  //     handleError(error)
  //   }
  // };

  return (
    <>
      {/* <WithPermission permission={'hasSales'} fallback={<SalesFallback />}>
        <div style={{ flexGrow: 1 }}>
            <DataSelectionTable records={payouts}
                                columns={payoutColumns}
                                filter={
                                  <PayoutGenerator
                                    onSelect={handleSelect}
                                  />
                                }
            />
        </div>
      </WithPermission>
      <RequestConfirmedModal open={open} onClose={() => setOpen(false)}/> */}
      <FormSection> 
        <Description>We apologize!</Description>
        <Description>The Payouts section is not currently available as we are currently working on improving it to provide an even better experience for you.</Description>
        <Description>In the meantime, if you need detailed information about your payouts, don&#39;t hesitate to reach out to your Account Manager or email us at hello@membrz.club.</Description>
        <Description>We are happy to assist!</Description>
      </FormSection>
    </>
)};

export default StripePayoutsTabContent;
