import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import AmountModal from './AmountModal';
import PaymentModal from './PaymentModal';
import isMobileDevice from 'utils/mobileAndTabletCheck';

const defaultModalState = {
    amountModal: false,
    paymentModal: false
};


const CheckoutModal = ({ product, onProductChange, onClose }) => {
  
  const defaultCheckoutVars = {
      amount: product.min_amount || 1,
      maxAmount: 1,
      step: 1,
      promoCode: ""
  };

  const [checkoutVars, setCheckoutVars] = useState(defaultCheckoutVars);
    const [modalsState, setModalsState] = useState(defaultModalState);
    
    const setStep = (step) => {
        setCheckoutVars({ ...checkoutVars, step });
    };

    const { amountModal, paymentModal } = modalsState;
      
    const handlePurchase = () => {
        setModalsState({ ...modalsState, amountModal: false, paymentModal: true });
    };

    const handleClose = () => {
        setCheckoutVars(defaultCheckoutVars);
        setModalsState(defaultModalState);
        onClose();
    };

    const handlePurchaseRequest = () => {
        const { redeem_limit, redeemed_count, issued_counter, supply_limit } = product;
    
        const newMaxAmount = Math.min(
            supply_limit ? supply_limit - issued_counter : Infinity,
            redeem_limit ? redeem_limit - redeemed_count : Infinity
        );
    
        setCheckoutVars({ ...checkoutVars, maxAmount: newMaxAmount });
        setModalsState({ ...modalsState, amountModal: true });

    };
    
    useEffect(() => {
        handlePurchaseRequest();
    }, []);
    
    const isMobile = isMobileDevice();
    const showLargeModal = !isMobile && checkoutVars.step === 1;
    const modalWidth = showLargeModal ? "md" : "sm";

  return (
    <>
        <AmountModal
            open={ amountModal }
            onClose={ handleClose }
            modalWidth={ modalWidth }
            object={ product }
            onObjectChange={ onProductChange }
            checkoutVars={ checkoutVars }
            onCheckoutVarsChange={ setCheckoutVars }
            done={ handlePurchase } />
 
        <PaymentModal
            pricingId={ product?.pricing_id }
            object={ product }
            open={ paymentModal }
            step={ checkoutVars.step }
            promoCode={ checkoutVars.promoCode }
            setStep={ setStep }
            handleModalsClose={ handleClose }
            stripe_purchase={ true }
            modalWidth={ modalWidth }
            amount={ checkoutVars.amount } />
        </>
  );
};

CheckoutModal.propTypes = {
    product: PropTypes.object,
    onProductChange: PropTypes.func,
    onClose: PropTypes.func
};

export default CheckoutModal;