import React from 'react';
import PropTypes from 'prop-types';
import { useHistory, useParams } from 'react-router-dom';

import Button from 'ui/Button';

import { t } from "i18n/index";

const ButtonGroup = ({ external_url, slug }) => {

  const isEventsPage = !!external_url;

  const history = useHistory();
  const {organizationSlug} = useParams();

  const handleRedirect = () => {
    const isInternal = external_url.startsWith(`${window.location.origin}/${organizationSlug}`);

    isInternal
      ? history.push(`/${organizationSlug}/events/${slug}`)
      : window.open(external_url, "_blank")
  };

  const buttonLabel = isEventsPage 
                        ? t('literal:seeDetails') 
                        : t('literal:buyTickets');

  return (
    <div className='button-group'>
        <Button 
          onClick={handleRedirect}
          fullwidth >
          {buttonLabel}
        </Button>
    </div>
  );
};

ButtonGroup.propTypes = {
    external_url: PropTypes.string,
    isDraft: PropTypes.bool,
    slug: PropTypes.string
  };

export default ButtonGroup;
