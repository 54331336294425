const handleSize = (size) => {
  return size.toString().includes("px") ? size : `${size}px`;
};

const createThemeFormData = (theme) => {
  const formData = new FormData();

  const {
    layout, primary_background_color,banner_size,
    title_color, buy_button_color, button_text_color,
    text_color, metadata_text_color, metadata_box_color, 
    secondary_background_color, title_size, subtitle_size, 
    description_size, padding_page, font_family, 
    highlight_color, custom_cta, custom_cta_action,
  } = theme;

  const fields = [
    { key: "layout", value: layout },
    { key: "primary_background_color", value: primary_background_color },
    { key: "banner_size", value: banner_size },
    { key: "title_color", value: title_color },
    { key: "buy_button_color", value: buy_button_color },
    { key: "button_text_color", value: button_text_color },
    { key: "text_color", value: text_color },
    { key: "metadata_text_color", value: metadata_text_color },
    { key: "metadata_box_color", value: metadata_box_color },
    { key: "secondary_background_color", value: secondary_background_color },
    { key: "title_size", value: handleSize(title_size) },
    { key: "subtitle_size", value: handleSize(subtitle_size) },
    { key: "description_size", value: handleSize(description_size) },
    { key: "padding_page", value: padding_page },
    { key: "font_family", value: font_family },
    { key: "highlight_color", value: highlight_color },
  ];

  if (custom_cta) {
    fields.push({ key: "custom_cta", value: custom_cta });
  }

  if (custom_cta_action) {
    fields.push({ key: "custom_cta_action", value: custom_cta_action });
  }

  fields.forEach((field) => {
    formData.append(field.key, field.value);
  });
  return formData;
};

export default createThemeFormData;
