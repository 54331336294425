export const createFormData = (pageElement) => {
    const formData = new FormData();

    const {element_type, reward_ids, link_title, content, file, number_of_events, view} = pageElement;
    const fields = [
        { key: 'element_type', value: element_type },
        { key: 'reward_ids', value: reward_ids },
        { key: 'link_title', value: link_title },
    ];
    
    if (number_of_events) fields.push({ key: 'number_of_events', value: number_of_events});
    if (view)             fields.push({ key: 'view', value: view});
    if (content)          fields.push({ key: 'content', value: content});
    if (file)             fields.push({ key: 'image', value: file });

    fields.forEach(field => {
        formData.append(field.key, field.value);
    });
    
    return formData;
};