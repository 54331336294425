const columns = {
  "orders.csv": "Order ID,Status,User Name,User Email,Invoice Name,Invoice Email,Vat,Address,Item Name,Item Price,Quantity,Net Value,Service Fees,Discount,Discount Detail,Total Value (VAT included),Currency,Payment Method,Completed At,Created at,Custom Fields",
  "admin_orders.csv": "Order ID,Status,Organization ID, Organization Name, Organization Country,User Name,User Email,Invoice Name,Invoice Email,Vat,Address,Item Name,Item Price,Quantity,Net Value,Service Fees,Discount,Discount Detail,Total Value (VAT included),Currency,Payment Method,Completed At,Created at",
  "claimed_nfts.csv": "Title #Serial_Number,NFT Type,User Name,User Email,User Wallet,Status,TX Id,Date Issued,Date Claimed",
  "products.csv": "Status,Reference,OrderId,Price,Product Id,Product Title,User Id,User Name,User Email,Created At,Custom Fields",
  "sl_products.csv": "Status,Reference,OrderId,Price,Product Id,Product Title,User Id,User Name,User Email,Created At,Motive,Custom Fields",
  "tickets.csv": "Status,Reference,OrderId,Price,Product Id,Product Title,User Id,User Name,User Email,Created At,Event Id, Event Title, Checked In At",
};

const generateCsvData = (response, csvColumns) => {
  const csvRows = response.map((element) => {

    const values = Object.values(element).map((value) => {
      if (typeof value === "string") {
        return `"${value.replace(/"/g, '""')}"`;
      } else if (value) {
        return `"${value}"`;
      } else {
        return "";
      }
    })
        
    return values.join(",");
  });
  return `${csvColumns}\n${csvRows.join("\n")}`;
};

const downloadFile = (data, filename, type) => {
  const blob = new Blob([data], { type: type });
  const url = URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.href = url;
  a.download = filename;
  a.click();
};

export const downloadCSV = (data, filename, type) => {
  const csvColumns = columns[filename];
  const csvData = generateCsvData(data, csvColumns);
  downloadFile(csvData, filename, type)
};

export const downloadFileFromUrl = (url, filename, type) => {
  fetch(url)
    .then((response) => response.blob())
    .then((blob) => {
      downloadFile(blob, filename, type);
    });
};
