import React from 'react';

import TableButton from 'components/TableButton';

import { getDateFormat } from 'utils/tables/getDateFormat';
import { getDateSelector } from 'utils/tables/getDateSelector';
import { convertPriceFromCents , currencySymbol } from 'utils/rewards';

import ZoomInIcon from "@mui/icons-material/ZoomIn";

const paymentTypes = {
  'stripe': 'Credit Card',
  'utrust': 'Crypto',
  'eupago': 'MBWay',
  'cash': 'Cash'
}

// const eventTypes = {
//   'subscription_purchase': 'Subscription Purchase',
//   'subscription_renewal': 'Subscription Renewal',
//   'collectible_purchase': 'Collectible Purchase',
// }

export const ordersColumns = (onClick) => {
  
  const columns = [
    {
      field: 'id',
      name: 'Order ID',
      sortable: true,
      width: "150px",
      selector: (row) => row.id,
    },
    {
      field: 'status',
      name: 'Status',
      sortable: true,
      width: "175px",
      selector: (row) => row.status
    },
    {
      field: 'user_name',
      name: 'User Name',
      sortable: true,
      width: "200px",
      selector: (row) => row.user,
    },
    {
      field: 'user_email',
      name: 'User Email',
      sortable: true,
      width: "300px",
      selector: (row) => row.email,
    },
    {
      field: 'item_name',
      name: 'Item Name',
      sortable: true,
      width: "400px",
      selector: (row) => row.item_title
    },
    {
      field: 'item_price',
      name: 'Item Price',
      sortable: true,
      width: "150px",
      selector: (row) => `${convertPriceFromCents(row.item_price)} ${currencySymbol[row.currency]}`
    },
    {
      field: 'amount',
      name: 'Quantity',
      sortable: true,
      width: "150px",
      selector: (row) => row.amount
    },
    {
      field: 'net_value',
      name: 'Net Value',
      sortable: true,
      width: "150px",
      selector: (row) => `${convertPriceFromCents(row.net_value)} ${currencySymbol[row.currency]}`
    },
    {
      field: 'application_fee',
      name: 'Service Fees',
      sortable: true,
      width: "150px",
      selector: (row) => `${row.application_fee ? convertPriceFromCents(row.application_fee) : 0} ${currencySymbol[row.currency]}`
    },
    {
      field: 'discount',
      name: 'Discount',
      sortable: true,
      width: "150px",
      selector: (row) => `${row.discount ? convertPriceFromCents(row.discount) : 0} ${currencySymbol[row.currency]}`
    },
    {
      field: 'total_value',
      name: 'Total Value',
      sortable: true,
      width: "150px",
      selector: (row) => `${convertPriceFromCents(row.total_value)} ${currencySymbol[row.currency]}`
    },
    {
      field: 'payment_provider',
      name: 'Payment Method',
      width: "200px",
      selector: (row) => paymentTypes[row.payment_provider]
    },
    {
      field: 'completed_at',
      name: 'Completed At',
      sortable: true,
      width: "200px",
      selector: (row) => getDateSelector(row.charge_succeded_at),
      format: (row) => getDateFormat(row.charge_succeded_at, 'lll'),
    },
    {
      field: 'created_at',
      name: 'Created At',
      sortable: true,
      width: "200px",
      selector: (row) => getDateSelector(row.created_at),
      format: (row) => getDateFormat(row.created_at, 'lll'),
    },
    {
      field: 'invoice_name',
      name: 'Invoice Name',
      sortable: true,
      width: "200px",
      selector: (row) => row.invoice_name,
    },
    {
      field: 'vat',
      name: 'Vat',
      sortable: true,
      width: "200px",
      selector: (row) => row.vat,
    },
    {
      selector : (row) =>  
        <div>
          <TableButton 
            title={"See Details"}
            Icon={ZoomInIcon}
            action={() => onClick(row)}
          />
        </div>,
      sortable: false,
      width: '5%'
    },
  ];

  return columns;
};