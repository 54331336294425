import React from 'react';
import PropTypes from 'prop-types';

import NumberOfEventsSelector from './NumberOfEventsSelector';
import ViewSelector from './ViewSelector';

const CalendarComposer = ({number_of_events, view, onChange}) => {
  return (
    <>
      <NumberOfEventsSelector
        number_of_events={number_of_events}
        onChange={onChange}
      />
      <ViewSelector
        view={view}
        onChange={onChange}
      />
    </>
  );
};

CalendarComposer.propTypes = {
  number_of_events:  PropTypes.string,
  view: PropTypes.bool,
  onChange: PropTypes.func,
};

export default CalendarComposer;