import React from 'react';
import PropTypes from 'prop-types';

import QRCodeGenerator from 'components/QRCodeGenerator';

import { urlChecker } from './urlChecker';

const SingleImage = ({ object }) => {

  const isUrl = urlChecker(object);
  return (
    <div className="single-image-backdrop">
        {isUrl ? <img src={object} /> : <QRCodeGenerator url={object} />}
    </div>
  );
};

SingleImage.propTypes = {
  object: PropTypes.string
};

export default SingleImage;
