import React from "react";
import PropTypes from "prop-types";

import { TextField } from "@mui/material";

import { capitalize } from "utils/capitalize";

const Content = ({ element_type, content, onChange }) => {

  const label = {
    title: "Title",
    default: capitalize(element_type).replace("_embed", " Url"),
  };

  return (
    <TextField
      size="small"
      name="content"
      label={element_type ? label[element_type] || label.default : "Content"} 
      value={content}
      onChange={onChange}
      fullWidth
      color="warning"
    />    
  );
};

Content.propTypes = {
  element_type: PropTypes.string,
  content:  PropTypes.string,
  onChange: PropTypes.func,
};

export default Content;
