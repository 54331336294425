import React from "react";
import { useHistory, useParams } from "react-router-dom";
import PropTypes from "prop-types";
import { useSuspense } from "@rest-hooks/react";

import EventResource from "resources/Profile/EventResource";

import CardGrid from "containers/public/Profile/Body/Events/EventsList/CardGrid";

import Event from "ui/Modules/Event";
import Button from "ui/Button";

import { t } from "i18n/index";

const Calendar = ({ view }) => {

    const {organizationSlug} = useParams();
    const events = useSuspense(EventResource.list(), {organization_slug: organizationSlug});
    const showViewAll = events.length > 0 && events[0].show_view_all;

    const history = useHistory();
    const handleRedirect = () => {
      history.push(`/${organizationSlug}/events`);
    };

  return (
    <div className="calendar">

      {view === "list" ?
        events.map((event, index) => (
          <Event key={index} event={event} />
        ))
        :
        <div className='grid-calendar'>
          <CardGrid events={events} />
        </div>
      }

      {showViewAll &&
        <Button minwidth secondary onClick={handleRedirect}>{t('literal:viewAll')}</Button>
      }
    </div>
  );
};

Calendar.propTypes = {
  view: PropTypes.string,
};

export default Calendar;
