import React, { useState } from "react";
import PropTypes from "prop-types";
import { useController } from "@rest-hooks/react";
import { useParams } from "react-router-dom";

import AddressForm from "components/AddressForm";

import { handleValidation } from "./handleValidation";

import Button from "ui/Button";
import FormSection from "ui/FormSection";

import { useError } from "utils/useErrorController";

import TextField from "@mui/material/TextField";

import { t } from "i18n/index";

const emptyInvoice = {
  name: '',
  email: '',
  vat: '',
  address: '',
  city: '',
  country: '',
  post_code: '',
};

const InvoiceForm = ({ record, InvoiceResource, paymentIntentId, onDone }) => {
  
  const [ invoiceData, setInvoiceData ] = useState({...emptyInvoice, ...record});
  
  const { loading, setLoading, handleError, validate } = useError();
  const { organizationSlug } = useParams();
  const { fetch } = useController();

  const handleChange = ({ target: { name, value } }) => {
    const newInvoice = { ...invoiceData, [name]: value };
    setInvoiceData(newInvoice);
  };

  const action = invoiceData.id ? "update" : "create";
  const requestParams = invoiceData.id ? { id: invoiceData.id } : {organization_slug: organizationSlug, payment_intent_id: paymentIntentId,};
   
  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      validate(invoiceData, handleValidation);
      await fetch( InvoiceResource[action](), requestParams, invoiceData );
      setLoading(false);
      onDone(6);
    } catch (error) {
      handleError(error);
    }
  };

  const textFieldsContent = {
    name: {
      label: t("literal:name"),
      name: "name",
      value: invoiceData.name,
      onChange: handleChange,
    },
    vat: {
      label: t("literal:vat"),
      name: "vat",
      value: invoiceData.vat,
      onChange: handleChange,
    },
    email: {
      label: t("literal:email"),
      name: "email",
      value: invoiceData.email,
      onChange: handleChange,
    },
  };

  const renderTextField = ({ label, name, value, onChange }) => {
    return (
      <TextField
        label={label}
        name={name}
        value={value || ""}
        onChange={onChange}
        fullWidth
        size="small"
        color="warning"
      ></TextField>
    );
  };

  return (
    <div style={{ display: "flex", flexDirection: "column", gap: 15 }}>
      <FormSection title={t("literal:personalOrCompanyDetails")} slim>
        {renderTextField(textFieldsContent["name"])}
        {renderTextField(textFieldsContent["vat"])}
      </FormSection>

      <FormSection title={t("literal:contactDetails")} slim>
        {renderTextField(textFieldsContent["email"])}
      </FormSection>

      <AddressForm
        onChange={handleChange}
        data={invoiceData}
        onDataChange={setInvoiceData}
        end_user={true}
      />

      <Button fullwidth loading={loading} onClick={(e) => handleSubmit(e)}>
        {invoiceData.id ? t("literal:submitChanges") : t("literal:submit")}
      </Button>
    </div>
  );
};

InvoiceForm.propTypes = {
  record: PropTypes.object,
  InvoiceResource: PropTypes.object,
  paymentIntentId: PropTypes.string,
  onDone: PropTypes.func
};

export default InvoiceForm;
