import React from "react";
import PropTypes from "prop-types";

import { Stack, Typography, Switch } from "@mui/material";

const ViewSelector = ({view, onChange}) => {

  const handleChange = ({target: {checked}}) => {
    const newValue = checked ? "grid" : "list";
    onChange({target: {name: "view", value: newValue }});
  };

  return(
    <Stack direction="row" spacing={0.5} alignItems="center" alignSelf="center">
      <Typography>Show as List</Typography>
          <Switch color="warning"
              checked={view === "grid"}
              onChange={handleChange} />
      <Typography>Show as Grid</Typography>
    </Stack>
  );
};

ViewSelector.propTypes = {
  view: PropTypes.bool,
  onChange: PropTypes.func
};

export default ViewSelector;
