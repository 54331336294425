import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import { markdownCustomHTML } from 'utils/markdownCustomHTML';

import '@toast-ui/editor/dist/toastui-editor.css';
import { Editor } from '@toast-ui/react-editor';

import media_gallery_logo from '../assets/images/media-gallery-icon.png'
import reset_icon from '../assets/images/reset_icon.svg'

const MarkdownEditor = ({
  object, 
  setObject, 
  attribute, 
  placeholder, 
  height="auto", 
  allowImageUpload=false, 
  allowEmbeds=false, 
  onMediaGalleryAdd=false, 
  onEmailReset=false,
  setUnsavedChanges
}) => {

  const editorRef = useRef(null);
  const handleMarkdownChange = () => {
    if (setUnsavedChanges) setUnsavedChanges(true);
    const editorInstance = editorRef.current.getInstance();
    const content = editorInstance.getMarkdown();
    setObject({...object, [attribute]: content});
  };

  const toolbarItems = [
      ['heading', 'bold', 'italic'],
      ['quote', 'ul', 'ol'],
      ['link'],
  ];

  if (allowImageUpload) {
      toolbarItems.push(['image'])
  }

  const createMediaGalleryButton = () => {
    const button = document.createElement('button');
    button.className = 'toastui-editor-toolbar-icons last media-gallery-button';
    button.style.backgroundImage = `url('${media_gallery_logo}')`;
    button.addEventListener('click', (e) => {
      e.preventDefault();
      e.stopPropagation();
      onMediaGalleryAdd();
    });

    return button;
  }

  //there is never onMediaGalleryAdd at present
  if (onMediaGalleryAdd) {
    toolbarItems[3].push({
        el: createMediaGalleryButton(),
        tooltip: 'Add Media Gallery'
    })
  }

  const createEmailResetButton = () => {
    const button = document.createElement('button');
    button.className = 'toastui-editor-toolbar-icons last email-reset-button';
    button.style.backgroundImage = `url('${reset_icon}')`;
    button.addEventListener('click', (e) => {
      e.preventDefault();
      e.stopPropagation();
      onEmailReset();
    });

    return button;
  }

  if (onEmailReset) {
    toolbarItems.push([{
      el: createEmailResetButton(),
      tooltip: 'Reset Email and Subject'
    }])
  }

  const customHTMLRenderer = allowEmbeds ? markdownCustomHTML : null;

  useEffect(() => {
    const editorInstance = editorRef.current.getInstance();

    editorInstance.eventEmitter.removeEventHandlerWithTypeInfo('query');
    editorInstance.eventEmitter.listen('query', (query, payload = {}) => {

      if (query === 'getPopupInitialValues') {
        if (payload.popupName === 'link') {
          const range = editorInstance.getSelection();
          
          const info = editorInstance.getRangeInfoOfNode(Math.floor((range[0] + range[1]) / 2));
          if (info.type === 'link') {
            editorInstance.setSelection(...info.range);
            let link = window.getSelection().getRangeAt(0).commonAncestorContainer.parentElement;

            link = link.closest('a') || link.querySelector('a') || link;
            return {
              linkUrl: link.href,
              linkText: link.innerText,
            };
          } else {
            return {
              linkText: editorInstance.getSelectedText(),
            };
          }
        }
      }
    });
  }, []);

  return (
    <Editor
      previewStyle="vertical"
      ref={editorRef}
      initialValue={object && object[attribute] ? object[attribute] : ''}
      placeholder={placeholder}
      height={height}
      initialEditType="wysiwyg"
      toolbarItems={toolbarItems}
      hideModeSwitch={true}
      onChange={handleMarkdownChange}
      customHTMLRenderer={customHTMLRenderer}
    />
  );
};

MarkdownEditor.propTypes = {
    object: PropTypes.object,
    setObject: PropTypes.func,
    attribute: PropTypes.string,
    placeholder: PropTypes.string,
    height: PropTypes.string,
    allowImageUpload: PropTypes.bool,
    allowEmbeds: PropTypes.bool,
    onMediaGalleryAdd: PropTypes.func,
    onEmailReset: PropTypes.func,
    setUnsavedChanges: PropTypes.func
};

export default MarkdownEditor;