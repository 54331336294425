import React, { useEffect } from 'react';
import { useController } from '@rest-hooks/react';
import { useParams } from 'react-router-dom';

import RewardInstanceDownloadResource from 'resources/organization/RewardInstanceDownloadResource';
import RewardInstanceResource from 'resources/organization/RewardInstanceResource';

import DataSelectionTable from 'components/DataTable/DataSelectionTable';
import AccountingAlert from 'components/AccountingAlert';

import Stats from './Stats';

import SearchableFilter from 'ui/SearchableFilter';

import { downloadCSV } from 'utils/downloadFile';
import { getRewardInstancesColumns } from 'utils/tables/columns/getRewardInstancesColumns';
import usePaginationAndFilteringController from "utils/usePaginationAndFilteringController";
import { useError } from 'utils/useErrorController';

const RewardInstancesTabContent = () => {
  const { fetch } = useController();
  const { handleError } = useError();

  const params = useParams();
  const organizationId = parseInt(params.organizationId);

  const {
    objectState,
    fetchData,
    handleFilterChange,
    handleQueryChange,
    handlePageChange
  } = usePaginationAndFilteringController({
      fetchDataResource: RewardInstanceResource,
      fetchDataParams: { organization_id: organizationId },
  })

  useEffect(() => {
    fetchData();
  }, []);

  const handleDownload = async () => {
    try {
        const response = await fetch(RewardInstanceDownloadResource.list(), {
          organization_id: organizationId,
          ...objectState.filteringParams,
         });
        downloadCSV(response, 'claimed_nfts.csv', 'text/csv');
    } catch (error) {
        handleError(error.message);
    }
  };

  const allowMinting = objectState.filteredObjects.length > 0 && objectState.filteredObjects[0].allow_minting;

  return (
    <>
        <AccountingAlert context={'pass'}/>
        <Stats />
        <div style={{ flexGrow: 1 }}>
          <DataSelectionTable records={objectState.filteredObjects}
                              columns={getRewardInstancesColumns(allowMinting)}
                              onQueryChange={handleQueryChange}
                              queryString={objectState.queryString}
                              searchLabel="Search by Name, Email or Wallet Address"

                              onClick={handleDownload}
                              serverPaginationProps={{
                                paginationServer: true,
                                paginationTotalRows: objectState.totalCount,
                                page: objectState.page,
                                onChangePage: handlePageChange
                              }}
                              filter={
                                <SearchableFilter
                                  label="pass"
                                  onChange={handleFilterChange}
                                />
                              }
          />
        </div>
    </>
)};

export default RewardInstancesTabContent;
