import React from 'react';
import PropTypes from 'prop-types';

import TypeSelector from './TypeSelector';
import ContentComposer from './ContentComposer';
import LinkComposer from './LinkComposer';
import SocialSelector from './SocialSelector';
import EmbedSelector from './EmbedSelector';
import LinkSelector from './LinkSelector';
import CalendarComposer from './CalendarComposer';

import LockSelector from 'components/LockSelector';

import { isSocialElement, isEmbeddedElement } from 'utils/pageElementsHelper';

import { DialogContent } from '@mui/material';
import FormSection from 'ui/FormSection';

const Content = ({ pageElement, setPageElement, passes, orgHasCalendar }) => {

  const {id, element_type, reward_ids, content, number_of_events, view} = pageElement;

  const showSocialSelector         = element_type === "social" || isSocialElement(element_type);
  const showEmbedSelector          = element_type === "embed" || isEmbeddedElement(element_type);
  const showContentComposer        = !!element_type && element_type !== "calendar";
  const showTypeSelector           = !element_type;
  const showLinkSelector           = element_type === "link" || showEmbedSelector || element_type === "calendar";
  const showPassSelector           = element_type === "link" || showEmbedSelector;

  const handleChange = ({target: {name, value}}) => {
    let newPageElement = { ...pageElement, [name]: value };

    if ((name === "element_type" && value === "social") || (name === "element_type" && value === "title")) {
        newPageElement["reward_ids"] = ["public"];
    }

    if ((name === "element_type" && value !== "link")) {
        newPageElement["file"] = null;
        newPageElement["filename"] = "";
    }

    setPageElement(newPageElement);
  };

  return (
    <DialogContent dividers>
        <FormSection slim>
            {showTypeSelector &&
                <TypeSelector
                    element_type={element_type}
                    onChange={handleChange}
                />
            }

            {!id && showLinkSelector &&
                <LinkSelector
                    element_type={element_type}
                    onChange={handleChange}
                    orgHasCalendar={orgHasCalendar}
                />
            }

            {!id && showSocialSelector &&
                <SocialSelector
                    element_type={element_type}
                    onChange={handleChange}
                />
            }

            { showEmbedSelector &&
                <EmbedSelector
                    element_type={element_type}
                    onChange={handleChange}
                />
            }

            {showContentComposer &&
                <ContentComposer
                    element_type={element_type}
                    content={content}
                    onChange={handleChange}
                />
            }

            { element_type === "calendar"  &&
              <CalendarComposer
                number_of_events={number_of_events}
                view={view}
                onChange={handleChange}
              />            
            }

            { element_type === "link" &&
                <LinkComposer
                    object={pageElement}
                    onObjectChange={setPageElement}
                    onChange={handleChange}
                />
            }

            {showPassSelector &&
                <LockSelector
                    reward_ids={reward_ids}
                    onChange={handleChange}
                    passes={passes} />
            }
        </FormSection>
    </DialogContent>
  );
};

Content.propTypes = {
    pageElement: PropTypes.object,
    setPageElement: PropTypes.func,
    passes: PropTypes.array,
    orgHasCalendar: PropTypes.bool
};

export default Content;
