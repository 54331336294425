import React from 'react';
import PropTypes from 'prop-types';
import { useHistory, useParams, Link } from 'react-router-dom';

import Button from 'ui/Button';

import { t } from "i18n/index";

const InvoiceCreated = ({ handleClaimReset }) => {
  
  const {organizationSlug} = useParams();
  const history = useHistory()

  const handleRedirect = () => {
    handleClaimReset();
    history.push(`/${organizationSlug}`);
  };

  const text = <>{t('profile:memberships:claimModal:invoiceCreated')} <Link to={`/orders`}>{t('literal:here')}</Link></> 

  return (
    <div className='payment-processed'>
      <p>{text}</p>
      <div className='button-group'>
        <Button onClick={handleRedirect} fullwidth>{t('literal:close')}</Button>
      </div>      
    </div>
  );
};

InvoiceCreated.propTypes = {
    handleClaimReset: PropTypes.func,
};

export default InvoiceCreated;

