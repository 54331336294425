import React, { useEffect, useState } from 'react';
import { useController } from '@rest-hooks/react';
import PropTypes from 'prop-types';

import OrganizationResource from 'resources/organization/OrganizationResource';
import ThemeResource from 'resources/organization/ThemeResource';
import PublicThemeResource from 'resources/public/ThemeResource';

import Form from './form';
import handleValidation from './handleValidation';
import createFormData from './createFormData';
import createThemeFormData from './createThemeFormData';

import { useError } from 'utils/useErrorController';
import customToast from 'utils/customToast';
import { useToast } from 'utils/context/ToastContext';
import { defaultTheme } from "utils/themes";
import { useParams } from 'react-router-dom';

const UpdateOrganization = ({ onUnsavedChanges }) => {

  const { fetch } = useController();
  const { setOpen, setMessage, setSeverity } = useToast();
  const { loading, validate, setLoading, handleError } = useError();

  const params = useParams();
  const organizationId = parseInt(params.organizationId);

  const [organization, setOrganization] = useState({});

  const fetchData = async () => {
    try {
      const themesArray = await fetch(PublicThemeResource.list(), { id: organizationId });
      const organizationData = await fetch(OrganizationResource.detail(), { id: organizationId });

      const { highlight_color, custom_cta, custom_cta_action } = themesArray[0]?.published_theme?.theme || {};

      const newOrganization = { ...organizationData, highlight_color, custom_cta, custom_cta_action };
      setOrganization(newOrganization);

    } catch (error) {
      handleError(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const submitOrganizationChanges = async (e) => {
    try {
      e.preventDefault();
      validate(organization, handleValidation);

      const formData = createFormData(organization);
      await fetch(OrganizationResource.update(), { id: organizationId }, formData );

      const { highlight_color, custom_cta, custom_cta_action } = organization;

      if (highlight_color || custom_cta || custom_cta_action) {

        const data = {...defaultTheme}
        if (highlight_color) data["highlight_color"] = highlight_color;
        if (custom_cta) data["custom_cta"] = custom_cta;
        if (custom_cta_action) data["custom_cta_action"] = custom_cta_action;

        const themeFormData = createThemeFormData(data);
        await fetch(ThemeResource.update(), {organization_id: organizationId}, themeFormData);
        //updates draft theme to final
        await fetch(ThemeResource.create(), {organization_id: organizationId}, {});
      }

      await fetch(OrganizationResource.list());
      customToast('success', `Organization Details Updated`, setOpen, setSeverity, setMessage);
      setLoading(false);
      onUnsavedChanges(false);

    } catch (error) {
      handleError(error);
    }
  };

  return (
    <Form organization={organization}
          onOrganizationChange={ setOrganization }
          onUnsavedChanges={ onUnsavedChanges }
          onSubmit={ (event) => submitOrganizationChanges(event) }
          loading={loading}
    />
  )
};

UpdateOrganization.propTypes = {
    record: PropTypes.object,
    onUnsavedChanges: PropTypes.func,
};

export default UpdateOrganization;
