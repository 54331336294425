import React from 'react';
import PropTypes from "prop-types";

import OrderSummary from "components/OrderSummary";

import AmountSelector from '../../AmountSelector';
import PromoCodeValidator from './PromoCodeValidator';

import Button from "ui/Button";

import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

import { t } from "i18n/index";

const AmountModal = ({ open, onClose, modalWidth, object, onObjectChange, checkoutVars, onCheckoutVarsChange, done }) => {

  const { id, title, image_url, unit_price, discount_price, net_discount, application_fee, organization, session_start_date, has_promo_codes, min_amount} = object;
  const { organization_title, show_application_fee, currency } = organization;

  const handleAmountChange = (amount) => onCheckoutVarsChange({ ...checkoutVars, amount });

  return (
    <Dialog
      open={open}
      fullWidth
      maxWidth={modalWidth}
      className="amount-modal"
    >
      <IconButton
        onClick={onClose}
        sx={{
            position: 'absolute',
            right: 8,
            top: 12,
            color: 'black',
        }}
        >
          <CloseIcon />
      </IconButton>
      <DialogContent>
        <OrderSummary
          currency={currency}
          unit_price={unit_price}
          discount_price={discount_price}
          net_discount={net_discount}
          quantity={checkoutVars.amount}
          frequency={'lifetime'}
          imageUrl={image_url}
          organizationTitle={organization_title}
          title={title}
          applicationFee={application_fee}
          session_start_date={session_start_date}
          showApplicationFee={show_application_fee}
        />

        {has_promo_codes &&
          <PromoCodeValidator
            objectId={id}
            onObjectChange={onObjectChange}
            checkoutVars={checkoutVars}
            onCheckoutVarsChange={onCheckoutVarsChange}
          />}

        <AmountSelector
          amount={checkoutVars.amount}
          maxAmount={checkoutVars.maxAmount}
          onAmountChange={handleAmountChange}
          minAmount={min_amount}
        />

        <Button highlighted fullwidth onClick={done}>{t('literal:proceed')}</Button>

      </DialogContent>
    </Dialog>
  );
};

AmountModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  modalWidth: PropTypes.string,
  object: PropTypes.object,
  onObjectChange: PropTypes.func,
  checkoutVars: PropTypes.object,
  onCheckoutVarsChange: PropTypes.func,
  done: PropTypes.func
};

export default AmountModal;
