import React from "react";
import PropTypes from "prop-types";

import { IconButton } from "@mui/material";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";

const AmountSelector = ({ amount, maxAmount, onAmountChange, minAmount }) => {

  if (maxAmount === 1) return null;
  const increment = minAmount || 1;

  const atMaxAmount = maxAmount && maxAmount <= amount;
  const atMinAmount = increment == amount;

  const deduct = () => onAmountChange(amount - increment);
  const add = () => onAmountChange(amount + increment);

  return (
    <div className="amount-selector">
      <div className="button">
        <IconButton
          className={`MuiAmountIconButton ${ atMinAmount && "MuiAmountIconButtonDisabled" }`}
          onClick={deduct}
          disableRipple={true}
          disabled={atMinAmount}
        >
          <RemoveIcon />
        </IconButton>
      </div>

      <span>{amount}</span>

      <div className="button">
        <IconButton
          className={`MuiAmountIconButton ${ atMaxAmount && "MuiAmountIconButtonDisabled" }`}
          onClick={add}
          disableRipple={true}
          disabled={atMaxAmount}
        >
          <AddIcon />
        </IconButton>
      </div>
    </div>
  );
};

AmountSelector.propTypes = {
  amount: PropTypes.number,
  maxAmount: PropTypes.number,
  onAmountChange: PropTypes.func,
  minAmount: PropTypes.number
};

export default AmountSelector;
