import React from 'react';
import PropTypes from 'prop-types';

import Alert from 'ui/Alert';

const AccountingAlert = ({context}) => {
  return (
    <Alert primaryText={`Track detailed ${context} sales and revenue and use filters to refine your view. For more detailed accounting information, including orders, fees, and billing, visit the 'Orders' tab.`} />
  );
};

AccountingAlert.propTypes = { 
  context: PropTypes.string
};

export default AccountingAlert;