import React from 'react';
import PropTypes from 'prop-types';

import StatCard from 'components/StatCard'
import { formatPrice } from 'utils/rewards';

const OrderStats = ({ orders }) => {

  const order = orders?.[0];

  const stat1 = order ? formatPrice(order.net_value_sum, order.currency, true) : 0
  const stat2 = order ? formatPrice(order.application_fee_sum, order.currency, true) : 0
  const stat3 = order ? formatPrice(order.total_value_sum, order.currency, true) : 0

  return (
    <div className="orders-stats-grid grid custom-margin">
      
      <StatCard 
        type='number'
        color='rgb(255, 99, 132)'
        highlight={stat1}
        title='Net Value' />

      <StatCard 
        type='number'
        color='rgb(54, 162, 235)'
        highlight={stat2}
        title='Service Fees' />

      <StatCard 
        type='number'
        color='rgb(255, 205, 86)'
        highlight={stat3}
        title='Total Value' />

    </div>
  )
};

OrderStats.propTypes = {
  orders: PropTypes.array
};

export default OrderStats;